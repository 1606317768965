import React from 'react';
import * as S from './style';
import {Text, Button} from '../../Atom';
import {Comment} from '../../Organism';
import {Image} from 'antd';
const Board = ({
	title,
	author,
	date,
	content,
	listBtn,
	files = [],
	comments = [],
}) => {
	let fileForm = /(.*?)\.(jpg|jpeg|png|gif|bmp|pdf)$/;
	return (
		<S.Container>
			<S.TitleWrapepr>
				<Text {...title} />
			</S.TitleWrapepr>
			<S.AuthorWrapepr>
				<Text {...author} />
			</S.AuthorWrapepr>
			<S.DateWrapper>
				<Text {...date} />
			</S.DateWrapper>
			<S.Divider />
			<S.ContentWrapper>
				<Text {...content} />
				{files.map((file) => (
					<div style={{marginTop: '2rem', marginBottom: '2rem'}}>
						<Image src={file.url} />
					</div>
				))}
			</S.ContentWrapper>
			{comments.map((comment) => (
				<Comment {...comment} />
			))}
			<S.ButtonWrapper>
				<Button {...listBtn} />
			</S.ButtonWrapper>
		</S.Container>
	);
};

export default Board;
