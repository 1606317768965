import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';

export const summary = {
	title: {
		text: {...cardTitleProps, children: '대회개요'},
	},
	uls: [
		{
			listStyle: 'title',
			items: [
				{
					title: {...ulsTitleProps, children: '대회명'},
					content: {
						...ulsContentProps,
						children: '제13회 MBC 섬진강 꽃길 마라톤대회',
						fontWeight: fontWeight.bold,
					},
				},
				{
					title: {...ulsTitleProps, children: '일시'},
					content: {
						...ulsContentProps,
						children: '2024년 4월 14일 (일)',
					},
				},
				{
					title: {...ulsTitleProps, children: '접수마감'},
					content: {
						...ulsContentProps,
						children: '2024년 3월 29일 (금) 24:00까지',
					},
				},
				{
					title: {...ulsTitleProps, children: '장소'},
					content: {
						...ulsContentProps,
						children: '광양 신원둔치주차장(섬진강매화로 1300)',
					},
				},
				{
					title: {...ulsTitleProps, children: '종목'},
					content: {
						...ulsContentProps,
						children: '풀코스, 하프코스, 10km, 5km',
					},
				},
				{
					title: {...ulsTitleProps, children: '참가비'},
					content: {
						...ulsContentProps,
						children: `- 풀코스(30,000원)\n- 하프코스(30,000원)\n- 10km코스(25,000원)\n- 5km코스(10,000원)`,
					},
				},
				{
					title: {...ulsTitleProps, children: '참가자격'},
					contents: [
						{
							...ulsContentProps,
							children: '나이제한 없으며, 신체 건강한 남/여',
						},
						{
							...ulsContentProps,
							children: `대한육상경기연맹 등록선수 및 선수등록 말소 후 2년 경과되지 않은 자는 참가할 수 없습니다.`,
							fontWeight: fontWeight.bold,
						},
					],
				},
				{
					title: {...ulsTitleProps, children: '문의'},
					content: {
						...ulsContentProps,
						children: `사무국 연락처 : 061-921-5500\n사무국 팩스 : 061-921-9906\n사무국 운영시간/09:00~18:00(월~금), 점심시간/12:00~13:00`,
					},
				},
				{
					title: {...ulsTitleProps, children: '주최'},
					content: {
						...ulsContentProps,
						children:
							'여수MBC  ⦁ MBC경남 ⦁ 광양시체육회 ⦁ 하동군체육회',
					},
				},
				{
					title: {...ulsTitleProps, children: '주관'},
					content: {
						...ulsContentProps,
						children:
							'여수MBC  ⦁ MBC경남 ⦁ 광양시체육회 ⦁ 하동군체육회',
					},
				},
				{
					title: {...ulsTitleProps, children: '후원'},
					content: {
						...ulsContentProps,
						children: '전라남도, 경상남도, 광양시, 하동군',
					},
				},
			],
		},
	],
};

export const way = {
	title: {
		text: {...cardTitleProps, children: '신청방법'},
	},
	uls: [
		{
			listStyle: 'title',
			items: [
				{
					title: {...ulsTitleProps, children: '인터넷'},
					content: {
						...ulsContentProps,
						children: '인터넷으로만 접수 http://www.mbcrun.com',
						fontWeight: fontWeight.bold,
					},
				},
				{
					title: {...ulsTitleProps, children: '현장접수'},
					content: {
						...ulsContentProps,
						children: `현장접수는 종목별 선착순 30명으로 제한합니다.\n※ 현장접수자는 시상에서 제외됩니다.`,
					},
				},
			],
		},
	],
};

export const feeTextProps = {
	base: {
		fontWeight: fontWeight.medium,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl},
	},
	accent: {
		fontWeight: fontWeight.bold,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl3},
	},
};

export const fee = {
	display: {
		desktop: 'table',
		tablet: 'table',
		mobile: 'none',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'table',
			tablet: 'table',
			mobile: 'none',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
			{...thProps, children: '입금계좌'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '30,000'},
				{
					...tdProps,
					childrens: [
						{...feeTextProps.base, children: '입금은행 : 광주은행'},
						{
							...feeTextProps.base,
							children: '예금주: 여수문화방송(주)',
						},
						{
							...feeTextProps.accent,
							children: '1107-021-668789',
						},
					],
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '30,000'},
			],
			[
				{...tdProps, children: '10km코스'},
				{...tdProps, children: '25,000'},
			],
			[
				{...tdProps, children: '5km코스'},
				{...tdProps, children: '10,000'},
			],
		],
	},
};

export const mobileFee = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '30,000'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '30,000'},
			],
			[
				{...tdProps, children: '10km코스'},
				{...tdProps, children: '25,000'},
			],
			[
				{...tdProps, children: '5km코스'},
				{...tdProps, children: '10,000'},
			],
		],
	},
};

export const mobileDeposit = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [{...thProps, children: '입금계좌'}],
		trs: [
			[
				{
					...tdProps,
					childrens: [
						{...feeTextProps.base, children: '입금은행 : 광주은행'},
						{
							...feeTextProps.base,
							children: '예금주: 여수문화방송(주)',
						},
						{...feeTextProps.accent, children: '1107-021-668789'},
					],
				},
			],
		],
	},
};

export const refundPolicy = {
	title: {
		text: {...cardTitleProps, children: '환불규정'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'입금 확인은 입금 다음 날부터 가능 (접수 마감일까지 미입금시 자동 접수 취소)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `본인 실수로 인해 입금 확인이 되지 않을 경우 참가 불가 (10% 공제 후 환불)`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `반드시 신청자 본인 명의 입금 (단체는 입금자명에 단체명 표기해 입금)`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'공식 접수마감 이후 취소나 종목 변경 불가 (연장접수기간 취소 및 환불 불가)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'접수마감 전 취소시 10% 공제 후 환불 (환불 소요기간은 약 1 주일 소요)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'참가자가 본인 이름으로 입금하지 않아 , 입금 확인이 되지 않을 경우 , 참가할 수 없으며 입금 금액의 10% 를 공제한 후 환불 (환불 소요기간은 약 1 주일 소요)',
					},
				},
			],
		},
		// {
		// 	listStyle: 'title',
		// 	items: [
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 신청 기간',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'참가 접수기간 중에만 가능 (참가 접수 마감 후에는 불가능)',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 신청방법',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children: '홈페이지 자유게시판에 비밀글로 직접 신청',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불액 지급 방법',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'개인 계좌를 통한 환불 처리 (환불 신청 후 7일 ~ 10일 소요)',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 금액',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'참가비의 10%를 제외한 금액 (사무국 운영, 은행 수수료 등 제반 경비)',
		// 			},
		// 		},
		// 	],
		// },
	],
};

export const schedule = {
	title: {
		text: {...cardTitleProps, children: '대회일정'},
	},
	table: {
		ths: [
			{...thProps, children: '일자'},
			{...thProps, children: '시간'},
			{...thProps, children: '내용'},
		],
		trs: [
			[
				{...tdProps, children: '2024년 4월 14일(일)', rowSpan: '10'},
				{...tdProps, children: '08:00 ~ 08:30'},
				{...tdProps, children: '안내, 식전행사'},
			],
			[
				{...tdProps, children: '08:30'},
				{...tdProps, children: '개회선언(여수MBC)'},
			],
			[
				{...tdProps, children: '09:00'},
				{...tdProps, children: '풀코스 출발'},
			],
			[
				{...tdProps, children: '09:10'},
				{...tdProps, children: '하프코스 출발'},
			],
			[
				{...tdProps, children: '09:20'},
				{...tdProps, children: '10km코스 출발'},
			],
			[
				{...tdProps, children: '09:30'},
				{...tdProps, children: '5km코스 출발'},
			],
			[
				{...tdProps, children: '10:30'},
				{...tdProps, children: '10km코스 시상'},
			],
			[
				{...tdProps, children: '11:20'},
				{...tdProps, children: '하프코스 시상'},
			],
			[
				{...tdProps, children: '12:30'},
				{...tdProps, children: '풀코스 시상'},
			],
			[
				{...tdProps, children: '14:00'},
				{...tdProps, children: '대회 종료'},
			],
		],
		descriptions: [
			{
				content: {
					children: `행사 일정은 현장 상황에 따라 변동될 수 있습니다.`,
				},
			},
		],
	},
};
