import React from 'react';
import * as S from './style';
import {Text} from '../../Atom';
import dayjs from 'dayjs';
import {boardLockImg} from '../../../config/images';

const Borads = ({
	titles = [],
	contents = [],
	count = 0,
	page = 0,
	onClick,
	category = '자유',
}) => {
	return (
		<S.Container>
			<S.TitleWrapper>
				{titles.map((title) => (
					<S.Title {...title}>
						<Text {...title.text} />
					</S.Title>
				))}
			</S.TitleWrapper>
			{contents.map((content, index, array) => (
				<S.ContentWrapper
					key={index}
					onClick={() =>
						onClick({
							id: content.id,
							isPassword: content.is_password,
						})
					}
				>
					<React.Fragment>
						<S.Content>
							<Text children={count - (page - 1) * 10 - index} />
						</S.Content>
						<S.ContentTitle flex={5}>
							{content.is_password ? (
								<S.Img src={boardLockImg} alt='lock' />
							) : (
								''
							)}
							<Text children={content.title} />
						</S.ContentTitle>
						<S.Content
							display={{
								desktop: 'flex',
								tablet: 'flex',
								mobile: 'none',
							}}
						>
							<Text children={content.author} />
						</S.Content>
						<S.Content
							display={{
								desktop: 'flex',
								tablet: 'flex',
								mobile: 'none',
							}}
						>
							<Text
								children={dayjs(content.created)?.format(
									'YYYY-MM-DD',
								)}
							/>
						</S.Content>
						{category !== '환불' ? (
							<S.Content
								display={{
									desktop: 'flex',
									tablet: 'flex',
									mobile: 'none',
								}}
							>
								<Text children={content.comment_count} />
							</S.Content>
						) : null}
						<S.Content
							display={{
								desktop: 'flex',
								tablet: 'flex',
								mobile: 'none',
							}}
						>
							{category === '환불' ? (
								<Text
									children={
										content.comments?.length ? 'O' : 'X'
									}
								/>
							) : (
								<Text children={content.hit} />
							)}
						</S.Content>
					</React.Fragment>
				</S.ContentWrapper>
			))}
		</S.Container>
	);
};

export default Borads;
