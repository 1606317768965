import {Input, Row, Select} from 'antd';
import {useRef} from 'react';
import styled from 'styled-components';
import {colorPalette, viewSize} from '../../../config';
import TextForm from '../TextForm';
import {Container, FormWrapper, RowWrapper, TextWrapper} from './style';

export default function InputNumberForm({
	text,
	englishText,
	category = 'default',
	info,
	setInfo,
	warnText,
}) {
	const textProps = {
		text,
		englishText,
		category,
	};

	const onChangePWInput = (event, name) => {
		const value = event.target.value;
		if (
			!Number(value) &&
			value !== '0' &&
			value !== '00' &&
			value !== '000' &&
			value !== '0000' &&
			value !== ''
		) {
			return;
		}
		setInfo((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container>
			<RowWrapper>
				<TextWrapper>{text && <TextForm {...textProps} />}</TextWrapper>
				<BoxWrapper>
					<StyledInput
						value={info.password}
						maxLength={4}
						onChange={(event) =>
							onChangePWInput(event, 'password')
						}
						placeholder='비밀번호 4자리(숫자)를 입력해 주세요.'
					/>
				</BoxWrapper>
			</RowWrapper>
			{warnText?.children !== '' && (
				<Row justify='start' style={{width: '100%'}}>
					<ErrorMeassage>{warnText?.children}</ErrorMeassage>
				</Row>
			)}
		</Container>
	);
}

const BoxWrapper = styled(Row)({
	alignItems: 'center',
	flexFlow: 'nowrap',
	justifyContent: 'space-between',
	width: '75%',
	height: 'auto',
	'@media screen and (max-width: 992px)': {
		width: '100%',
	},
});

const StyledInput = styled(Input)({
	width: '100%',
	borderColor: '#9B9B9B',
	height: '3.25rem',
	borderRadius: 0,
	fontSize: '18px',
	fontFamily: 'Pretendard',
	padding: '10px 16px',
	'@media screen and (max-width: 450px)': {
		width: '100%',
	},
});

const ErrorMeassage = styled.p({
	color: '#ff0000',
	marginLeft: 300,
	'@media screen and (max-width: 450px)': {
		marginLeft: 0,
	},
});
