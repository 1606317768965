import styled from 'styled-components';
import {colorPalette} from '../../../config';

export const Container = styled.div`
	width: auto;
	background-color: #e9f8ff;
	border-radius: 14px;
	max-width: -webkit-fill-available;
	padding: 35px;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;

	> span {
		border-radius: 1rem;
		color: #484848;
		font-weight: bold;
		::before {
			content: 'A';
			border-radius: 50px;
			width: 29px;
			height: 29px;
			color: white;
			background-color: #414c80;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
		}
	}
`;

export const ContentWrapper = styled.div`
	margin-left: 1rem;
`;
