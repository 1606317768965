import {Radio, Typography} from 'antd';
import styled from 'styled-components';
import {
	courseFilter,
	depositFilter,
	genderFilter,
} from '../../../Pages/Admin/Participation/data';

export default function FliterBox({type = 'paticipation', filter, setFilter}) {
	return (
		<FilterBox>
			<FilterBoxItemBox>
				<FilterTitle>입금여부</FilterTitle>
				<Radio.Group
					optionType='button'
					onChange={(e) =>
						setFilter({...filter, is_deposit: e.target.value})
					}
					options={depositFilter}
					value={filter.is_deposit}
				/>
			</FilterBoxItemBox>
			{type === 'paticipation' && (
				<>
					<FilterBoxItemBox>
						<FilterTitle>성별선택</FilterTitle>
						<Radio.Group
							optionType='button'
							onChange={(e) =>
								setFilter({...filter, gender: e.target.value})
							}
							options={genderFilter}
							value={filter.gender}
						/>
					</FilterBoxItemBox>
					<FilterBoxItemBox>
						<FilterTitle>코스선택</FilterTitle>
						<Radio.Group
							optionType='button'
							onChange={(e) =>
								setFilter({...filter, course: e.target.value})
							}
							options={courseFilter}
							value={filter.course}
						/>
					</FilterBoxItemBox>
				</>
			)}
		</FilterBox>
	);
}

export const FilterBox = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-around',
	alignItems: 'center',
	padding: '20px 0px',
});

export const FilterBoxItemBox = styled.div({
	paddingTop: 50,
	display: 'flex',
	flexDirection: 'column',
});

export const FilterTitle = styled(Typography.Text)({
	textAlign: 'center',
	marginBottom: 10,
});
