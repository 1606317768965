import {Button, Col, Input, Row, Space, Table, Typography} from 'antd';
import {useRef, useState} from 'react';
import {useEffect} from 'react';
import styled from 'styled-components';
import {getRecordList} from '../../../api';
import {getRecords} from '../../../api/admin';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import dayjs from 'dayjs';
export default function Record() {
	const [page, setPage] = useState(1);
	const [name, setName] = useState('');
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};

	const fetchRecords = async () => {
		getRecords({
			page,
			search,
		}).then((res) => {
			if (res?.isSuccess) {
				const results = res?.data?.results ?? [];
				const newData = results?.map((item) => {
					let year = dayjs(item.year)?.format('YYYY')
					return {
						name: item?.name ?? '',
						no: item?.no ?? '',
						course: item?.course ?? '',
						gender: item?.gender ?? '',
						allRecord: item?.full_record ?? '',
						returnRecord1: item?.half1_record ?? '',
						returnRecord2: item?.half2_record ?? '',
						returnRecord3: item?.half3_record ?? '',
						year: year,
					};
				});
				setTotal(res.data.count);
				setData(newData);
			}
		});
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => {
						const name = selectedKeys[0];
						fetchRecords(name);
						close();
					}}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => {
							const name = selectedKeys[0];
							fetchRecords(name);
							close();
						}}
						icon={<SearchOutlined />}
						size='small'
						style={{
							width: 90,
						}}
					>
						Search
					</Button>
					<Button
						onClick={() =>
							clearFilters && handleReset(clearFilters)
						}
						size='small'
						style={{
							width: 90,
						}}
					>
						Reset
					</Button>

					<Button
						type='link'
						size='small'
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: '연도',
			dataIndex: 'year',
			key: 'year',
		},
		{
			title: '이름',
			dataIndex: 'name',
			key: 'name',
			...getColumnSearchProps('name'),
		},
		{
			title: '배번',
			dataIndex: 'no',
			key: 'no',
		},
		{
			title: '코스',
			dataIndex: 'course',
			key: 'course',
		},
		{
			title: '성별',
			dataIndex: 'gender',
			key: 'gender',
		},
		{
			title: '전체기록',
			dataIndex: 'allRecord',
			key: 'allRecord',
			sorter: (a, b) =>
				Number(a?.allRecord?.replace(/:/g, '')) -
				Number(b?.allRecord?.replace(/:/g, '')),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: '1차반환기록',
			dataIndex: 'returnRecord1',
			key: 'returnRecord1',
			sorter: (a, b) =>
				Number(a?.returnRecord?.replace(/:/g, '')) -
				Number(b?.returnRecord?.replace(/:/g, '')),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: '2차반환기록',
			dataIndex: 'returnRecord2',
			key: 'returnRecord2',
			sorter: (a, b) =>
				Number(a?.returnRecord?.replace(/:/g, '')) -
				Number(b?.returnRecord?.replace(/:/g, '')),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: '3차반환기록',
			dataIndex: 'returnRecord3',
			key: 'returnRecord3',
			sorter: (a, b) =>
				Number(a?.returnRecord?.replace(/:/g, '')) -
				Number(b?.returnRecord?.replace(/:/g, '')),
			sortDirections: ['descend', 'ascend'],
		},
	];

	useEffect(() => {
		fetchRecords();
	}, [page, search]);

	return (
		<Container>
			<Row justify='center' style={{marginTop: 20}}>
				<Col>
					<Title>기록조회</Title>
				</Col>
			</Row>
			<Row justify='space-between'>
				<Col></Col>
				<Col>
					<Input.Search onSearch={(e) => setSearch(e)} />
				</Col>
			</Row>
			<StyledTable
				onChange={(pagination, filters, sorter, extra) => {
					setPage(pagination.current);
				}}
				pagination={{
					defaultCurrent: 1,
					total: total,
					showTotal: (total) => `${total} 명`,
				}}
				columns={columns}
				dataSource={data}
			/>
		</Container>
	);
}

const Container = styled.div({
	padding: 20,
});

const StyledTable = styled(Table)({
	marginTop: 40,
});

const Title = styled.p({
	fontWeight: 'bold',
	fontSize: 20,
});
