import React, {useEffect, useState} from 'react';
import {getBoard, postComment, deleteComment} from '../../../api/board';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Image,
	Input,
	Row,
	Space,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import {updateBoard} from '../../../api/admin';

const {Title, Paragraph, Text, Link} = Typography;

const BoardUpdateForm = ({id, category, onClose}) => {
	const [post, setPost] = useState({
		author: '',
		title: '',
		created: '',
		content: '',
		attachments: [],
		comments: [],
	});
	const [toggle, setToggle] = useState(false);

	const onChangeInput = (event, key) => {
		const value = event?.target?.value;
		setPost((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const updateBoardButton = () => {
		updateBoard({
			id,
			category,
			title: post.title,
			content: post.content,
		}).then((res) => {
			if (res?.isSuccess) {
				onClose();
			}
		});
	};

	useEffect(() => {
		(async () => {
			let res = await getBoard({id});
			setPost(res.data);
		})();
	}, [toggle, id]);
	return (
		<div style={{marginTop: 20}}>
			<Title level={5}>제목</Title>
			<StyledTitleInput
				value={post.title}
				onChange={(e) => onChangeInput(e, 'title')}
			/>
			<Divider />
			{post.author !== '' ? (
				<Title level={5}>작성자 : {post.author}</Title>
			) : (
				<Title level={5}>작성자 : </Title>
			)}
			<Title level={5}>
				작성일 : {dayjs(post.created)?.format('YYYY-MM-DD HH:mm')}
			</Title>

			<Divider />
			<Title level={5}>내용</Title>
			<TextArea
				value={post.content}
				onChange={(e) => onChangeInput(e, 'content')}
				rows={7}
			/>

			<Divider />

			{post.comments.map((comment) => (
				<Card
					title={comment.author}
					style={{margin: '1rem 0'}}
					extra={<Button
						onClick={async () => {
							await deleteComment({id: comment.id});
							setToggle(!toggle);
						}
					}>삭제</Button>}
				>
					<Paragraph>{comment.content}</Paragraph>
				</Card>
			))}
			{post.attachments.map((attachment) => {
				return (
					<>
						<Button
							onClick={() =>
								(window.location.href = attachment.file.url)
							}
						>
							{attachment.file.name}
						</Button>
						<Image style={{padding: '0px 0px 15px 0px'}} src={attachment?.file?.url}></Image>
					</>
				);
			})}

			<Form
				initialValues={{content: ''}}
				style={{margin: '1rem 0'}}
				onFinish={async (values) => {
					await postComment({
						board_id: id,
						content: values.content,
						author: '사무국',
					});
					setToggle(!toggle);
				}}
			>
				<Form.Item label='댓글' name='content'>
					<Input.TextArea />
				</Form.Item>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}
				>
					<div
						style={{
							textAlign: 'right',
							marginRight: 10,
						}}
					>
						<Button onClick={updateBoardButton}>수정하기</Button>
					</div>
					<div
						style={{
							textAlign: 'right',
						}}
					>
						<Button htmlType={'submit'}>댓글 달기</Button>
					</div>
				</div>
			</Form>
		</div>
	);
};

export default BoardUpdateForm;

const StyledTitleInput = styled(Input)({});
