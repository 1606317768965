import {Button, Checkbox, Col, Radio, Row, Space, Typography} from 'antd';
import {useState} from 'react';
import styled from 'styled-components';
import {
	exportPerson,
	exportGroup,
	exportParticipation,
	exportVolunteer,
} from '../../../api/admin';

export default function ExportExcelForm({checkBoxProps, type}) {
	const [fields, setFields] = useState([]);
	const [order, setOrder] = useState(type === 'exportGroup' ? 'name' : 'id');
	const [isDeposit, setIsDeposit] = useState('all');
	const [kind, setKind] = useState('all');
	const onClick = async () => {
		if (type === 'paticipation') {
			await exportParticipation({
				fields,
				order: order.split('-'),
				category: kind,
				is_deposit: isDeposit,
			});
		}
		if (type === 'person') {
			await exportPerson({
				fields,
				order: order.split('-'),
				category: kind,
				is_deposit: isDeposit,
			});
		}
		if (type === 'exportGroup') {
			await exportGroup({
				fields,
				order: order.split('-'),
				category: kind,
				is_deposit: isDeposit,
			});
		}
		if (type === 'volunteer') {
			await exportVolunteer({
				fields,
				order: order.split('-'),
				category: kind,
			});
		}
	};

	return (
		<>
			<Title>출력양식</Title>
			<LabelText>엑셀 출력 필드(선택 안할 시 전체)</LabelText>
			<Row>
				<Col>
					<Checkbox.Group
						style={{flexWrap: 'wrap'}}
						value={fields}
						onChange={(e) => {
							setFields(e);
						}}
					>
						{checkBoxProps.fields.items.map((field) => (
							<StyledCheckbox value={field.value}>
								{field.name}
							</StyledCheckbox>
						))}
					</Checkbox.Group>
				</Col>
			</Row>
			<LabelText>엑셀 출력 정렬</LabelText>
			<Row>
				<Col>
					<Radio.Group
						style={{flexWrap: 'wrap'}}
						onChange={(e) => setOrder(e.target.value)}
						value={order}
					>
						{type === 'exportGroup' ? (
							<>
								<StyledRadio value='name'>단체명</StyledRadio>
								<StyledRadio value='representative'>
									대표자명
								</StyledRadio>
							</>
						) : (
							<>
								<StyledRadio value='id'>입력순</StyledRadio>
								<StyledRadio value='gender-name'>
									성별(1순위) + 이름(2순위)
								</StyledRadio>
								<StyledRadio value='course-gender-name'>
									종목(1순위) + 성별(2순위) + 이름(3순위)
								</StyledRadio>
								<StyledRadio value='course-group__name-gender'>
									종목(1순위) + 단체(2순위) + 성별(3순위)
								</StyledRadio>
							</>
						)}
					</Radio.Group>
				</Col>
			</Row>

			{type === 'paticipation' ||
				(type === 'exportGroup' && (
					<>
						<LabelText>엑셀 출력 입금/미입금 구분</LabelText>
						<Row>
							<Col>
								<Radio.Group
									onChange={(e) => {
										setIsDeposit(e.target.value);
									}}
									value={isDeposit}
								>
									<Radio value={'all'}>전체</Radio>
									<Radio value={'deposit'}>입금</Radio>
									<Radio value={'no-deposit'}>미입금</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</>
				))}

			{type === 'paticipation' && (
				<>
					<LabelText>엑셀 출력 단체 구분</LabelText>
					<Row>
						<Col>
							<Radio.Group
								onChange={(e) => {
									setKind(e.target.value);
								}}
								value={kind}
							>
								<Radio value={'all'}>전체</Radio>
								<Radio value={'group'}>단체</Radio>
								<Radio value={'person'}>개인</Radio>
							</Radio.Group>
						</Col>
					</Row>
				</>
			)}
			<ButtonBox justify='center'>
				<Col>
					<StyledButton onClick={onClick} type='primary'>
						엑셀 출력
					</StyledButton>
				</Col>
			</ButtonBox>
		</>
	);
}

const Title = styled(Typography.Paragraph)({
	textAlign: 'center',
	fontSize: 20,
	fontWeight: 500,
});

const LabelText = styled(Typography.Paragraph)({
	marginTop: 20,
});

const StyledCheckbox = styled(Checkbox)({
	marginInlineStart: '0 !important',
	paddingBottom: 10,
});

const StyledRadio = styled(Radio)({
	paddingBottom: 10,
});

const ButtonBox = styled(Row)({
	marginTop: 20,
});

const StyledButton = styled(Button)({
	width: 320,
});
