import {Button, Col, Input, Row, Select, Space} from 'antd';
import styled from 'styled-components';

export default function TableHeaderBox({
	action,
	options,
	setAction,
	setSearch,
	doAction,
}) {
	return (
		<StyledRow justify='space-between'>
			<Col>
				<Row>
					<Space>
						<Select
							value={action}
							style={{width: '200px', marginRight: 2}}
							options={options}
							onChange={(e) => setAction(e)}
						/>
						<Button onClick={doAction}>실행</Button>
					</Space>
				</Row>
			</Col>
			<Col>
				<Input.Search onSearch={(e) => setSearch(e)} />
			</Col>
		</StyledRow>
	);
}

const StyledRow = styled(Row)({
	padding: 20,
});
