import React, {useEffect, useState} from 'react';
import * as S from './style';
import {
	actionOptions,
	checkBoxProps,
	columns,
	participatedFilter,
	searchProps,
	selectProps,
	tableProps,
} from './data';
import {orderInit} from '../Participation/data';
import {
	deleteVolunteer,
	deleteVolunteers,
	exportVolunteer,
	getListVolunteer,
} from '../../../api/admin';
import ModalVolunteerForm from '../ModalVolunteerForm';
import {dictToList, dictToStr} from '../../../util/postProcess';
import {
	Checkbox,
	Radio,
	Select,
	Input,
	Button,
	Table,
	Space,
	Modal,
	Typography,
	Row,
	Col,
} from 'antd';
import {ExportExcelForm, TableHeaderBox} from '../../../Components/Template';

const AdminVolunteer = () => {
	const [participation, setParticipation] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [ordering, setOrdering] = useState('');
	const [isAllCheck, setIsAllCheck] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [action, setAction] = useState('');
	const [search, setSearch] = useState('');
	const [fields, setFields] = useState([]);
	const [order, setOrder] = useState(orderInit);
	const [select, setSelect] = useState(0);
	const [modal, setModal] = useState(false);
	const [filter, setFilter] = useState({participated: ''});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [volunteerModal, setVolunteerModal] = useState(false);
	const [isExcelDownloadModal, setIsExcelDownLoadModal] = useState(false);

	useEffect(() => {
		(async () => {
			let res = await getListVolunteer({page, filter, search, ordering});
			if (!res.isSuccess) {
				return;
			}
			let data = res.data.results.map((item, idx) => ({
				...item,
				key: item.id,
				no: res.data.count - (page - 1) * 10 - idx,
			}));
			setParticipation(data);
			setTotal(res.data.count);
		})();
	}, [page, toggle, filter, search, ordering]);

	const doAction = async () => {
		switch (action) {
			case 'delete':
				await deleteVolunteers({ids: selectedRowKeys});
				break;
			default:
				return alert('액션을 설정해주세요');
		}
		setToggle(!toggle);
	};

	checkBoxProps.button.onClick = async () => {
		let field = dictToList({dict: fields});
		let ord = dictToStr({dict: order, defaultValue: 'id'});

		await exportVolunteer({
			fields: field,
			order: ord.split('-'),
		});
	};

	const setSorter = (sorts) => {
		if (!Array.isArray(sorts)) {
			sorts = [sorts];
		}

		let sortList = sorts.map((item) => {
			if (item.order) {
				return `${item.order === 'ascend' ? '' : '-'}${item.field}`;
			}
		});
		if (sortList) {
			setOrdering(sortList.join(','));
		}
	};
	return (
		<S.Container>
			<Row justify='center' style={{marginTop: 20}}>
				<Col>
					<Typography.Text>봉사날짜</Typography.Text>
				</Col>
			</Row>
			<Row justify='center' style={{marginTop: 20}}>
				<Col>
					<Radio.Group
						onChange={(e) => {
							setFilter({
								...filter,
								participated: e.target.value,
							});
						}}
						optionType={'button'}
						options={participatedFilter}
					/>
				</Col>
			</Row>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					padding: '10px 20px',
				}}
			>
				<Space>
					<Button
						type='primary'
						onClick={() => setVolunteerModal(true)}
					>
						봉사신청서 추가
					</Button>
					<Button
						type='primary'
						onClick={() => setIsExcelDownLoadModal(true)}
					>
						봉사 엑셀 다운로드
					</Button>
				</Space>
			</div>
			<TableHeaderBox
				action={action}
				options={actionOptions}
				setAction={setAction}
				setSearch={setSearch}
				doAction={doAction}
			/>

			<S.CheckBoxWrapper>
				<Table
					scroll={{x: 1300}}
					rowSelection={{
						selectedRowKeys,
						onChange: (e) => {
							setSelectedRowKeys(e);
						},
					}}
					dataSource={participation}
					onChange={(pagination, filters, sorter, extra) => {
						setPage(pagination.current);
						setSorter(sorter);
					}}
					pagination={{
						defaultCurrent: 1,
						total: total,
						showTotal: (total) => `${total} 명`,
					}}
				>
					{columns.map((col) => {
						if (col.dataIndex === 'name') {
							return (
								<Table.Column
									{...col}
									render={(text, record) => (
										<a
											onClick={() => {
												setSelect(record.id);
											}}
										>
											{text}
										</a>
									)}
								/>
							);
						}
						return <Table.Column align={'center'} {...col} />;
					})}
					<Table.Column
						title={'액션'}
						align={'center'}
						render={(_, record, idx) => (
							<Space>
								<Button onClick={() => setSelect(record.id)}>
									수정
								</Button>
								<Button
									onClick={async () => {
										await deleteVolunteer({
											id: record.id,
										});
										setToggle(!toggle);
									}}
								>
									삭제
								</Button>
							</Space>
						)}
					/>
				</Table>
			</S.CheckBoxWrapper>
			{volunteerModal && (
				<Modal
					onCancel={() => setVolunteerModal(false)}
					footer={[]}
					open={true}
					width='90%'
				>
					<ModalVolunteerForm
						onClick={() => {
							setVolunteerModal(false);
							setToggle(!toggle);
						}}
					/>
				</Modal>
			)}
			{select !== 0 && (
				<Modal
					onCancel={() => setSelect(0)}
					footer={[]}
					open={true}
					width='90%'
				>
					<ModalVolunteerForm
						id={select}
						onClick={() => {
							setSelect(0);
							setToggle(!toggle);
						}}
					/>
				</Modal>
			)}
			<Modal
				open={isExcelDownloadModal}
				onCancel={() => {
					setIsExcelDownLoadModal(false);
				}}
				footer={[]}
			>
				<ExportExcelForm
					checkBoxProps={checkBoxProps}
					type='volunteer'
				/>
			</Modal>
		</S.Container>
	);
};

export default AdminVolunteer;
