import axios from 'axios';
import {apiErrorParser} from '../util';
import {API, TOKEN as token} from '../config';

export const defaultApi = async ({
	data,
	params,
	url,
	method,
	raiseError = true,
	isRaw = false,
	responseType,
	headers,
}) => {
	try {
		let res = await axios({
			method,
			url: `${API}${url}`,
			data,
			params,
			responseType,
			headers,
		});
		if (isRaw) {
			return res;
		}
		return {isSuccess: true, status: res.status, data: res.data};
	} catch (e) {
		if (e?.response?.data === '신청기간이 지났습니다.') {
			return alert('신청기간이 지났습니다.');
		}
		if (e?.response?.data === '이미 신청된 참가자가 포함되어 신청이 불가능합니다.') {
			return alert('이미 신청된 참가자가 포함되어 신청이 불가능합니다.');
		}
		if (e?.response?.data === '기념품을 다시 선택해 주세요.') {
			return alert('기념품을 다시 선택해 주세요.\n풀/하프/10km코스/5km코스: 준비중입니다');
		}
		if (e?.response?.data === '생년월일 형식 오류') {
			return alert('생년월일 형식이 잘못 되었습니다. YYYY-MM-DD 형식이어야 합니다.');
		}
		if (e?.response?.data === '비밀번호 미입력') {
			return alert('비밀번호 입력은 필수입니다.');
		}
		if (raiseError) {
			apiErrorParser(e);
		}
		return {isSuccess: false, status: e.status, data: e.response.data};
	}
};

export const postPersonParticipation = async ({
	name,
	phone,
	gender,
	course,
	gift,
	birth,
	depositor,
	post_number,
	address,
	detail_address,
	email,
	verification,
	option,
	password,
}) => {
	let data = {
		token,
		verification,
		depositor,
		post_number,
		address,
		detail_address,
		email,
		password,
		participation: {
			name,
			gender,
			course,
			gift,
			phone,
			birth,
			option,
		},
	};
	return await defaultApi({
		data,
		url: '/participations/person/',
		method: 'POST',
	});
};

export const getListPersonParticipation = async ({name, birth, password}) => {
	let params = {
		name,
		birth,
		password,
		token,
	};

	return await defaultApi({
		params,
		url: '/participations/person/',
		method: 'GET',
	});
};

export const updatePersonParticipation = async ({
	id,
	name,
	phone,
	gender,
	course,
	gift,
	birth,
	depositor,
	post_number,
	address,
	detail_address,
	email,
	option,
}) => {
	let data = {
		token,
		depositor,
		post_number,
		address,
		detail_address,
		email,
		participation: {
			name,
			phone,
			gender,
			course,
			gift,
			birth,
			option,
		},
	};
	return await defaultApi({
		url: `/participations/person/${id}/`,
		data,
		method: 'PUT',
	});
};

export const postGroupParticipation = async ({
	name,
	representative,
	birth,
	phone,
	depositor,
	post_number,
	address,
	detail_address,
	participation,
	email,
	password,
	verification,
	raiseError,
}) => {
	let data = {
		token,
		name,
		representative,
		birth,
		phone,
		depositor,
		post_number,
		address,
		detail_address,
		participation,
		email,
		password,
		verification,
	};

	return await defaultApi({
		data,
		url: '/participations/group/',
		method: 'POST',
		raiseError,
	});
};

export const getListGroupParticipation = async ({
	name,
	representative,
	phone,
	verification,
	password,
}) => {
	let params = {
		name,
		representative,
		phone,
		token,
		verification,
		password,
	};

	return await defaultApi({
		params,
		url: '/participations/group/',
		method: 'GET',
	});
};

export const getListPersonToGroup = async ({
	name,
	representative,
	phone,
	verification,
	personToGroup = true,
}) => {
	let params = {
		name,
		representative,
		phone,
		token,
		verification,
		personToGroup,
	};

	return await defaultApi({
		params,
		url: '/participations/group/',
		method: 'GET',
	});
};

export const updateGroupParticipation = async ({
	id,
	name,
	representative,
	phone,
	birth,
	post_number,
	address,
	detail_address,
	depositor,
	email,
	participation,
}) => {
	let data = {
		token,
		name,
		representative,
		phone,
		birth,
		post_number,
		address,
		detail_address,
		depositor,
		email,
		participation,
	};
	return await defaultApi({
		data,
		url: `/participations/group/${id}/`,
		method: 'PUT',
	});
};

export const postVolunteer = async ({
	name,
	participated,
	school,
	grade,
	class_name,
	volunteer_id,
	birth,
	phone,
}) => {
	let data = {
		token,
		name,
		birth,
		phone,
		participated,
		school_name: school,
		grade,
		class_name: class_name,
		volunteer_id,
	};

	return await defaultApi({
		data,
		url: '/participations/volunteer/',
		method: 'POST',
	});
};

export const getListVolunteer = async ({name, phone}) => {
	let params = {
		name,
		phone,
		token,
	};
	return await defaultApi({
		params,
		url: '/participations/volunteer/',
		method: 'GET',
	});
};

export const updateVolunteer = async ({
	id,
	name,
	participated,
	school_name,
	grade,
	class_name,
	volunteer_id,
	birth,
	phone,
}) => {
	let data = {
		token,
		name,
		participated,
		school_name,
		grade,
		class_name,
		volunteer_id,
		birth,
		phone,
	};

	return await defaultApi({
		url: `/participations/volunteer/${id}/`,
		method: 'PUT',
		data,
	});
};

export const getRecordList = async ({
	name,
	no,
	gender,
	course,
	year,
	full_record,
	half1_record,
	half2_record,
	half3_record,
}) => {
	let params = {
		token,
		name,
		no,
		gender,
		course,
		year,
		full_record,
		half1_record,
		half2_record,
		half3_record,
	};

	return await defaultApi({
		params,
		url: '/participations/record/',
		method: 'GET',
	});
};

export const isExist = async ({name, birth}) => {
	let params = {
		token,
		name,
		birth,
	};

	return await defaultApi({
		params,
		url: '/participations/is_exist/',
		method: 'GET',
	});
};

export const isGroupExist = async ({name, representative, birth}) => {
	let params = {
		token,
		name,
		representative,
		birth,
	};

	return await defaultApi({
		params,
		url: '/participations/group/is_exist/',
		method: 'GET',
	});
};