import {
	inputTextProps,
	inputEnglishTextProps,
	formButtonProps,
	inputButtonProps,
	warnText,
	ulsContentProps,
} from '../../common';
import {onlyLetter, onlyNumber} from '../../../util/validator';
import {makeRecordCourse} from '../../../util';
export const firstProps = {
	inputs: [
		{
			type: 'input',
			name: 'name',
			text: {
				...inputTextProps,
				children: '이름',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name',
			},
			input: {
				...inputTextProps,
				name: 'name',
				pattern: onlyLetter,
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'radio',
			name: 'gender',
			text: {
				...inputTextProps,
				children: '성별',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Gender',
			},
			items: [
				{
					value: '남성',
					children: '남성',
				},
				{
					value: '여성',
					children: '여성',
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'no',
			text: {
				...inputTextProps,
				children: '배번호',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Bib number',
			},
			input: {
				...inputTextProps,
				name: 'no',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'radio',
			name: 'course',
			text: {
				...inputTextProps,
				children: '참가종목',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Participating events',
			},
			items: makeRecordCourse(),
			warnText: {
				...warnText,
			},
		},
	],
	button: {
		text: {
			...formButtonProps,
			children: '확인',
		},
	},
};

export const secondProps = {
	info: [
		[{title: {children: '연도'}, content: {children: '2023년'}}],
		[{title: {children: '성명'}, content: {children: '홍길동'}}],
		[{title: {children: '배 번호'}, content: {children: '0000'}}],
		[{title: {children: '전체 기록'}, content: {children: ''}}],
		[{title: {children: '1차 반환 기록'}, content: {children: ''}}],
		[{title: {children: '2차 반환 기록'}, content: {children: ''}}],
		[{title: {children: '3차 반환 기록'}, content: {children: ''}}],
	],
	cardContent: {
		uls: [
			{
				listStyle: 'circle',
				items: [
					{
						content: {
							...ulsContentProps,
							children:
								'기록에 문의가 있으신 분은 사무국으로 연락주시면 감사하겠습니다.',
						},
					},
					{
						content: {
							...ulsContentProps,
							children:
								'참가비 입금이 되어야 접수가 완료되며 참가자명과 입금자명이 다를 경우 필히 대회사무국으로 전화 주시기 바랍니다.',
						},
					},
				],
			},
		],
	},
	button: {
		text: {
			...formButtonProps,
			children: '수정하기',
		},
	},
};

export const initialInfo = {
	year: '',
	name: '',
	no: '',
	gender: '',
	course: '',
	full_record: '',
	half1_record: '',
	half2_record: '',
	half3_record: '',
};

export const invalidProps = {
	name: '',
	no: '',
	gender: '',
	course: '',
};
