import React, {useEffect, useState} from 'react';
import * as S from './style';
import {
	checkBoxProps,
	searchProps,
	selectProps,
	orderInit,
	depositInit,
	columns,
} from './data';
import {
	deleteGroup,
	deleteGroups,
	exportGroup,
	exportParticipation,
	getListGroup,
	setGroupDeposit,
	setGroupDeposits,
} from '../../../api/admin';
import {ModalGroupForm} from '../index';
import {dictToList, dictToStr} from '../../../util/postProcess';
import {
	Checkbox,
	Radio,
	Button,
	Input,
	Select,
	Table,
	Space,
	Modal,
	Typography,
	Row,
	Col,
} from 'antd';
import {
	ExportExcelForm,
	FliterBox,
	TableHeaderBox,
} from '../../../Components/Template';

const AdminGroup = () => {
	const [group, setGroup] = useState([]);
	const [page, setPage] = useState(1);
	const [ordering, setOrdering] = useState('');
	const [total, setTotal] = useState(0);
	const [toggle, setToggle] = useState(false);
	const [action, setAction] = useState('');
	const [search, setSearch] = useState('');
	const [fields, setFields] = useState([]);
	const [order, setOrder] = useState('id');
	const [deposit, setDeposit] = useState('all');
	const [select, setSelect] = useState(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [groupModal, setGroupModal] = useState(false);
	const [filter, setFilter] = useState({
		gender: '',
		is_deposit: '',
		course: '',
	});
	const [isExcelDownloadModal, setIsExcelDownLoadModal] = useState(false);

	useEffect(() => {
		(async () => {
			let res = await getListGroup({page, search, ordering, filter});
			if (!res.isSuccess) {
				return;
			}
			let data = res.data.results.map((item, idx) => {
				return {
					...item,
					no: res.data.count - (page - 1) * 10 - idx,
					key: item.id,
				};
			});
			setGroup(data);
			setTotal(res.data.count);
		})();
	}, [page, toggle, search, ordering, filter]);

	const doAction = async () => {
		switch (action) {
			case 'set_deposit':
				await setGroupDeposits({
					ids: selectedRowKeys,
					is_deposit: true,
				});
				break;
			case 'unset_deposit':
				await setGroupDeposits({
					ids: selectedRowKeys,
					is_deposit: false,
				});
				break;
			case 'delete':
				await deleteGroups({ids: selectedRowKeys});
				break;
			default:
				return alert('액션을 설정해주세요.');
		}
		setToggle(!toggle);
	};

	checkBoxProps.button.onClick = async () => {
		await exportGroup({
			fields: fields,
			order: order.split('-'),
			is_deposit: deposit,
		});
	};
	const setSorter = (sorts) => {
		if (!Array.isArray(sorts)) {
			sorts = [sorts];
		}

		let sortList = sorts.map((item) => {
			if (item.order) {
				return `${item.order === 'ascend' ? '' : '-'}${item.field}`;
			}
		});
		if (sortList) {
			setOrdering(sortList.join(','));
		}
	};

	return (
		<S.Container>
			<FliterBox filter={filter} setFilter={setFilter} type='group' />
			<Row justify='end' style={{padding: '10px 20px'}}>
				<Col>
					<Space>
						<Button
							type='primary'
							onClick={() => setGroupModal(true)}
						>
							단체 신청서 추가
						</Button>
						<Button
							type='primary'
							onClick={() => setIsExcelDownLoadModal(true)}
						>
							단체 엑셀 다운로드
						</Button>
					</Space>
				</Col>
			</Row>
			<TableHeaderBox
				action={action}
				options={selectProps.options}
				setAction={setAction}
				setSearch={setSearch}
				doAction={doAction}
			/>
			<S.CheckBoxWrapper>
				<Table
					dataSource={group}
					rowSelection={{
						selectedRowKeys,
						onChange: (e) => {
							setSelectedRowKeys(e);
						},
					}}
					onChange={(pagination, filters, sorter, extra) => {
						setPage(pagination.current);
						setSorter(sorter);
					}}
					pagination={{
						defaultCurrent: 1,
						total: total,
						showTotal: (total) => `${total} 단체`,
					}}
				>
					{columns.map((col) => {
						if (col.dataIndex === 'name') {
							return (
								<Table.Column
									{...col}
									render={(text, record) => (
										<a onClick={() => setSelect(record.id)}>
											{text}
										</a>
									)}
								/>
							);
						}
						return <Table.Column align={'center'} {...col} />;
					})}
					<Table.Column
						title={'입금여부'}
						align={'center'}
						dataIndex={'is_deposit'}
						render={(record) => (record ? '✅' : '❌')}
					/>
					<Table.Column
						align={'center'}
						title={'액션'}
						onChange={(pagination, filters, sorter, extra) => {
							setPage(pagination.current);
							setSorter(sorter);
						}}
						pagination={{
							defaultCurrent: 1,
							total: total,
						}}
						render={(_, record, index) => (
							<Space>
								<Button onClick={() => setSelect(record.id)}>
									수정
								</Button>
								<Button
									onClick={async () => {
										await setGroupDeposit({id: record.id});
										setToggle(!toggle);
									}}
								>
									입금/미입금
								</Button>
								<Button
									onClick={async () => {
										await deleteGroup({id: record.id});
										setToggle(!toggle);
									}}
								>
									삭제
								</Button>
							</Space>
						)}
					/>
				</Table>
			</S.CheckBoxWrapper>
			{groupModal && (
				<Modal
					width={'90%'}
					open={true}
					onCancel={() => setGroupModal(false)}
					footer={[]}
				>
					<ModalGroupForm
						onClick={() => {
							setGroupModal(false);
							setToggle(!toggle);
						}}
					/>
				</Modal>
			)}
			{select !== 0 && (
				<Modal
					width={'90%'}
					open={true}
					onCancel={() => setSelect(0)}
					footer={[]}
				>
					<ModalGroupForm
						id={select}
						onClick={() => {
							setSelect(0);
							setToggle(!toggle);
						}}
					/>
				</Modal>
			)}

			<Modal
				open={isExcelDownloadModal}
				onCancel={() => {
					setIsExcelDownLoadModal(false);
				}}
				footer={[]}
			>
				<ExportExcelForm
					type='exportGroup'
					checkBoxProps={checkBoxProps}
				/>
			</Modal>
		</S.Container>
	);
};

export default AdminGroup;
