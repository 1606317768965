import {Input, Row, Select} from 'antd';
import {useRef} from 'react';
import styled from 'styled-components';
import {colorPalette, viewSize} from '../../../config';
import TextForm from '../TextForm';
import {Container, FormWrapper, RowWrapper, TextWrapper} from './style';

export default function PhoneInput({
	text,
	englishText,
	category = 'default',
	info,
	setInfo,
	warnText,
}) {
	const phone2Ref = useRef();
	const textProps = {
		text,
		englishText,
		category,
	};

	const onChangeSelect = (value) => {
		setInfo((prev) => ({
			...prev,
			phone1: value,
		}));
	};
	const onChangePhoneInput = (event, name) => {
		const value = event.target.value;
		if (
			!Number(value) &&
			value !== '0' &&
			value !== '00' &&
			value !== '000' &&
			value !== '0000' &&
			value !== ''
		) {
			return;
		}
		setInfo((prev) => ({
			...prev,
			[name]: value,
		}));
		if (value.length === 4 && name === 'phone2') {
			phone2Ref?.current?.focus();
		}
	};

	return (
		<Container>
			<RowWrapper>
				<TextWrapper>{text && <TextForm {...textProps} />}</TextWrapper>
				<BoxWrapper>
					<Phone1Select
						value={{value: info.phone1, label: info.phone1}}
						onChange={onChangeSelect}
						defaultValue={{value: '010', label: '010'}}
						options={[
							{value: '010', label: '010'},
							{value: '011', label: '011'},
							{value: '02', label: '02'},
							{value: '070', label: '070'},
							{value: '051', label: '051'},
							{value: '053', label: '053'},
							{value: '032', label: '032'},
							{value: '062', label: '062'},
							{value: '042', label: '042'},
							{value: '052', label: '052'},
							{value: '044', label: '044'},
							{value: '031', label: '031'},
							{value: '033', label: '033'},
							{value: '043', label: '043'},
							{value: '041', label: '041'},
							{value: '063', label: '063'},
							{value: '061', label: '061'},
							{value: '054', label: '054'},
							{value: '055', label: '055'},
							{value: '064', label: '064'},
						]}
					/>

					<DashBox>-</DashBox>

					<StyledInput
						value={info.phone2}
						maxLength={4}
						onChange={(event) =>
							onChangePhoneInput(event, 'phone2')
						}
					/>

					<DashBox>-</DashBox>
					<StyledInput
						ref={phone2Ref}
						value={info.phone3}
						maxLength={4}
						onChange={(event) =>
							onChangePhoneInput(event, 'phone3')
						}
					/>
				</BoxWrapper>
			</RowWrapper>
			{warnText?.children !== '' && (
				<Row justify='start' style={{width: '100%'}}>
					<ErrorMeassage>{warnText?.children}</ErrorMeassage>
				</Row>
			)}
		</Container>
	);
}

const Phone1Select = styled(Select)({
	height: 'auto !important',
	'&&&& .ant-select-selector': {
		borderColor: '#9B9B9B',
		height: '3.25rem !important',
		width: '100%',
		borderRadius: 2,
		padding: '10px 16px',
		fontSize: '18px',
		'@media screen and (max-width: 992px)': {
			width: '100%',
			fontSize: '16px',
		},
	},
	width: 265,
	'@media screen and (max-width: 992px)': {
		width: 265,
	},
});

const BoxWrapper = styled(Row)({
	alignItems: 'center',
	flexFlow: 'nowrap',
	justifyContent: 'space-between',
	width: '75%',
	height: 'auto',
	'@media screen and (max-width: 992px)': {
		width: '100%',
	},
});

const DashBox = styled.div({
	margin: '0 2%',
	'@media screen and (max-width: 430px)': {
		margin: '0px 9px',
	},
});

const StyledInput = styled(Input)({
	width: 265,
	borderColor: '#9B9B9B',
	height: '3.25rem !important',
	borderRadius: 0,
	fontSize: '18px',
	padding: '10px 16px',
	'@media screen and (max-width: 450px)': {
		width: '100%',
		fontSize: '16px',
	},
});

const ErrorMeassage = styled.p({
	color: '#ff0000',
	marginLeft: 300,
	'@media screen and (max-width: 450px)': {
		marginLeft: 0,
	},
});
