import React, {useState, useEffect} from 'react';
import {BreadCrumb, PersonForm} from '../../../Components/Template';
import {firstProps, secondProps, invalidProps, textareaProps} from './data';
import {makeOption, setForm, setWarnText} from '../../../util';
import {isValidate} from '../../../util/validator';
import {isExist, postPersonParticipation} from '../../../api';
import {CheckBox, Container, Text, TextArea} from '../../../Components/Atom';
import styled from 'styled-components';
import {viewSize, colorPalette} from '../../../config';
import {
	firstProgress,
	secondProgress,
	expiredIMG,
} from '../../../config/images';
import {useDaumPostcodePopup} from 'react-daum-postcode';
import {getInfo} from '../../../api/admin';
import dayjs from 'dayjs';

export const TopWRapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 1rem;
	> img {
		margin: auto 0 auto auto;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		flex-direction: column-reverse;

		> img {
			margin: auto auto auto 0;
		}
	}
`;

export const CheckBoxWrapper = styled.div`
	margin: 1rem 0;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: baseline;
	> div {
		margin-right: 1rem;
	}
`;

export const ExpiredImg = styled.img`
	width: 50%;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
	}
`;
export const ExpiredText = styled.div`
	color: ${colorPalette.primary};
	font-family: 'Gmarket';
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: 20px;
	}
`;

const Person = () => {
	const [info, setInfo] = useState({
		name: '',
		phone1: '010',
		phone2: '',
		phone3: '',
		year: '',
		month: '',
		day: '',
		gender: '',
		post_number: '',
		address: '',
		detail_address: '',
		email: '',
		depositor: '',
		course: '',
		gift: '',
		option: '',
		password: '',
	});

	const [check, setCheck] = useState(false);
	const [invalid, setInvalid] = useState(invalidProps);
	const [section, setSection] = useState(0);
	const open = useDaumPostcodePopup();

	const handleComplete = (data) => {
		let post_number = data.zonecode;
		let address = data.address;

		setInfo({
			...info,
			post_number,
			address,
		});
	};

	useEffect(() => {
		(async () => {
			let res = await getInfo();
			if (dayjs().diff(dayjs(res.data.results[0].ended), 'second') > 0) {
				setSection(3);
			}
		})();
	});

	const handleClick = () => {
		open({onComplete: handleComplete});
	};
	const firstInfo = {
		name: info.name,
		phone1: info.phone1,
		phone2: info.phone2,
		phone3: info.phone3,
		year: info.year,
		month: info.month,
		day: info.day,
		gender: info.gender,
	};

	firstProps.inputs.forEach((input) => {
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	firstProps.button.onClick = async () => {
		if (!info.phone1 || !info.phone2 || !info.phone3) {
			return alert('연락처를 입력해 주세요.');
		}
		if (!info.gender) {
			return alert('성별을 선택해 주세요.');
		}
		if (isValidate(firstInfo, invalidProps, setInvalid)) {
			if (!check) {
				return alert('약관에 동의하셔야 진행이 가능합니다.');
			}

			let res = await isExist({
				name: info.name,
				birth: `${info.year}-${info.month}-${info.day}`,
			});
			if (res.data.is_exist) {
				return alert(
					'이미 등록된 참가자가 있습니다. 사무국에 문의해 주세요.',
				);
			}
			setSection(1);
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	secondProps.inputs.forEach((input) => {
		if (document.getElementsByName('post_number')[0]) {
			if (input.name == 'post_number') {
				document.getElementsByName('post_number')[0].readOnly = true;
			}
			if (input.name == 'address') {
				document.getElementsByName('address')[0].readOnly = true;
			}
		}
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	secondProps.inputs[0].button.onClick = () => handleClick();

	secondProps.button.onClick = async () => {
		if (info.email == '@') {
			info.email = '';
		}
		if (!info.course) {
			return alert('참가 종목을 선택해 주세요.');
		}
		if (!info.gift) {
			return alert('기념품을 선택해 주세요.');
		}
		if (!info.password || info.password.trim().length != 4) {
			return alert('숫자 4자리 비밀번호를 입력해주세요.');
		}
		if (isValidate(info, invalidProps, setInvalid)) {
			if (info.gift.split(' - ').length == 2) {
				var gift = info.gift.split(' - ')[0];
				var option = info.gift.split(' - ')[1];
			} else {
				gift = info.gift;
				option = info.option;
			}
			let res = await postPersonParticipation({
				name: info.name,
				phone: `${info.phone1}-${info.phone2}-${info.phone3}`,
				birth: `${info.year}-${info.month}-${info.day}`,
				gender: info.gender,
				post_number: info.post_number,
				address: info.address,
				detail_address: info.detail_address,
				email: info.email,
				depositor: info.depositor,
				course: info.course,
				gift: gift,
				option: option,
				password: info.password,
			});

			if (!res.isSuccess) {
				return;
			}

			sessionStorage.setItem('name', info.name);
			sessionStorage.setItem(
				'birth',
				`${info.year}-${info.month}-${info.day}`,
			);
			sessionStorage.setItem('password', info.password);

			window.location.href =
				window.location.origin + '/participation/person-update';
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};
	return (
		<Container>
			<TopWRapper>
				<BreadCrumb
					depths={['HOME', '참가신청 하기', '개인 참가신청']}
				/>
				<img src={section === 0 ? firstProgress : secondProgress} />
			</TopWRapper>

			{section === 3 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ExpiredImg src={expiredIMG} />
					<ExpiredText>
						마라톤대회 참가 신청이 마감되었습니다.
					</ExpiredText>
				</div>
			)}

			{section === 0 && (
				<>
					<TextArea
						borderRadius={'1rem'}
						height={'228px'}
						disabled={true}
						value={textareaProps}
						fontSize={{
							desktop: '16px',
							tablet: '16px',
							mobile: '16px',
						}}
					/>
					<CheckBoxWrapper>
						<CheckBox
							value={check}
							onChange={(e) => {
								setCheck(e.target.value);
							}}
						></CheckBox>
						<Text>위의 사항에 대하여 모두 동의합니다.</Text>
					</CheckBoxWrapper>
				</>
			)}
			{section === 0 && (
				<PersonForm {...firstProps} info={info} setInfo={setInfo} />
			)}
			{section === 1 && (
				<PersonForm
					{...secondProps}
					info={info}
					setInfo={setInfo}
					isInfoText
				/>
			)}
		</Container>
	);
};

export default Person;
