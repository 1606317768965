import React, {useState, useEffect} from 'react';
import {firstProps, initialInfo, invalidProps} from './data';
import {BreadCrumb, PersonForm} from '../../../Components/Template';
import {setForm, setWarnText} from '../../../util';
import {isValidate} from '../../../util/validator';
import {postVolunteer} from '../../../api';
import {Container} from '../../../Components/Atom';
import {getInfo} from '../../../api/admin';
import dayjs from 'dayjs';
import {expiredIMG} from '../../../config/images';
import styled from 'styled-components';
import {viewSize, colorPalette} from '../../../config';

export const ExpiredImg = styled.img`
	width: 50%;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
	}
`;

export const ExpiredText = styled.div`
	color: ${colorPalette.primary};
	font-family: 'Gmarket';
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: 20px;
	}
`;

const Volunteer = () => {
	const [info, setInfo] = useState(initialInfo);
	const [section, setSection] = useState(0);

	useEffect(() => {
		(async () => {
			let res = await getInfo();
			if (dayjs().diff(dayjs(res.data.results[0].volunteer_ended), 'second') > 0) {
				setSection(3);
			}
		})();
	});

	const [invalid, setInvalid] = useState(invalidProps);
	firstProps.inputs.forEach((input) => {
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	firstProps.button.onClick = async () => {
		if (!info.phone1 || !info.phone2 || !info.phone3) {
			return alert('연락처를 입력해 주세요.')
		}
		if (isValidate(info, invalidProps, setInvalid)) {
			let res = await postVolunteer({
				...info,
				participated: info.participated.join(','),
				name: `${info.name}`,
				birth: `${info.year}-${info.month}-${info.day}`,
				phone: `${info.phone1}-${info.phone2}-${info.phone3}`,
			});

			if (!res.isSuccess) {
				return;
			}

			sessionStorage.setItem('name', info.name);
			sessionStorage.setItem(
				'phone',
				`${info.phone1}-${info.phone2}-${info.phone3}`,
			);

			window.location.href = `${window.location.href}-update`;
		}
	};

	return (
		<Container>
			<BreadCrumb depths={['HOME', '참가신청', '자원봉사 신청']} />
			{section === 3 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ExpiredImg src={expiredIMG} />
					<ExpiredText>
						마라톤대회 봉사 신청이 마감되었습니다.
					</ExpiredText>
				</div>
			)}
			{section === 0 && (
				<PersonForm {...firstProps} vInfoText info={info} setInfo={setInfo} />
			)}
		</Container>
	);
};

export default Volunteer;
