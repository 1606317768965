import styled from 'styled-components';

export const Container = styled.div`
    display : flex;
    flex-direction : column;
    width : 100%:
`;

export const TitleWrapper = styled.div`
	margin: 0.675rem 0;
	width: 100%;
	border: black;
`;

export const InputWrapper = styled.div`
	width: 100%;
`;
