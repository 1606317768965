import styled from 'styled-components';
import {colorPalette, viewSize} from '../../../config';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Link = styled.a`
	width: calc(100% / 3 - 15px);
	margin-top: 40px;
	margin-right: 22px;
	text-decoration: none;
	:nth-of-type(3n) {
		margin-right: 0;
	}
	color: ${colorPalette.navColor};
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
		margin-right: 0;
	}
`;
export const ImgWrapper = styled.div`
	> img {
		width: 100%;

		object-fit: cover;
		aspect-ratio: 1/1;
		object-position: center;
	}
`;

export const ImgTitle = styled.div`
	margin-bottom: 0.5rem;
`;

export const ImgCreated = styled.div``;

export const Img = styled.img`
	width: 100%;
`;
