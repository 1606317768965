import React, {useState, useEffect} from 'react';
import {isGroupExist, postGroupParticipation} from '../../../api';
import {BreadCrumb, PersonForm} from '../../../Components/Template';
import {
	setGroupForm,
	setAddGroup,
	setGroupDelete,
	setAllCheck,
	setToggleCheck,
	setForm,
	setWarnText,
	generateGroupParticipation,
} from '../../../util';
import {isValidate} from '../../../util/validator';
import {
	firstProps,
	groupProps,
	secondProps,
	invalidProps,
	textareaProps,
	errorTextValues,
} from './data';
import {CheckBox, Container, Text, TextArea} from '../../../Components/Atom';
import {CheckBoxWrapper, TopWRapper} from '../Person';
import {
	firstProgress,
	secondProgress,
	expiredIMG,
} from '../../../config/images';
import {viewSize, colorPalette} from '../../../config';
import {useDaumPostcodePopup} from 'react-daum-postcode';
import {Button, Modal, Row} from 'antd';
import styled from 'styled-components';
import {getInfo} from '../../../api/admin';
import dayjs from 'dayjs';

export const ExpiredImg = styled.img`
	width: 50%;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
	}
`;
export const ExpiredText = styled.div`
	color: ${colorPalette.primary};
	font-family: 'Gmarket';
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: 20px;
	}
`;
const Group = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [info, setInfo] = useState({
		name: '',
		representative: '',
		year: '',
		month: '',
		day: '',
		post_number: '',
		address: '',
		detail_address: '',
		email: '',
		phone1: '010',
		phone2: '',
		phone3: '',
		depositor: '',
	});
	const [group, setGroup] = useState([
		{
			check: false,
			name: '',
			gender: '',
			birth: '',
			phone1: '',
			phone2: '',
			phone3: '',
			course: '',
			gift: '',
		},
		{
			check: false,
			name: '',
			gender: '',
			birth: '',
			phone1: '',
			phone2: '',
			phone3: '',
			course: '',
			gift: '',
		},
	]);
	const [isAllCheck, setIsAllCheck] = useState(false);
	const [invalid, setInvalid] = useState(invalidProps);
	const [section, setSection] = useState(0);
	const [check, setCheck] = useState(false);
	const open = useDaumPostcodePopup();

	useEffect(() => {
		(async () => {
			let res = await getInfo();
			if (dayjs().diff(dayjs(res.data.results[0].ended), 'second') > 0) {
				setSection(3);
			}
		})();
	});

	const handleComplete = (data) => {
		let post_number = data.zonecode;
		let address = data.address;

		setInfo({
			...info,
			post_number,
			address,
		});
	};

	const handleClick = () => {
		open({onComplete: handleComplete});
	};

	let firstInfo = {
		name: info.name,
		representative: info.representative,
		year: info.year,
		month: info.month,
		day: info.day,
	};

	firstProps.inputs.forEach((input) => {
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	secondProps.inputs.forEach((input) => {
		if (document.getElementsByName('post_number')[0]) {
			if (input.name == 'post_number') {
				document.getElementsByName('post_number')[0].readOnly = true;
			}
			if (input.name == 'address') {
				document.getElementsByName('address')[0].readOnly = true;
			}
		}
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	firstProps.button.onClick = async () => {
		if (isValidate(firstInfo, invalidProps, setInvalid)) {
			if (!check) {
				return alert('약관에 동의하여 주시기 바랍니다.');
			}
			let res = await isGroupExist({
				name: info.name,
				representative: info.representative,
				birth: `${info.year}-${info.month}-${info.day}`,
			});
			if (res.data.is_exist) {
				return alert(
					'이미 등록된 단체가 있습니다. 사무국에 문의해 주세요.',
				);
			}
			setSection(1);
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	secondProps.button.onClick = async () => {
		if (isValidate(info, invalidProps, setInvalid)) {
			let res = await postGroupParticipation({
				...info,
				birth: `${info.year}-${info.month}-${info.day}`,
				phone: `${info.phone1}-${info.phone2}-${info.phone3}`,
				participation: generateGroupParticipation(group),
				raiseError: false,
			});
			if (!res.isSuccess) {
				const newErrorText = [];
				res.data.participation?.map((item, index) => {
					Object.keys(item)?.map((propsItem) => {
						newErrorText.push({
							index,
							title: `${index + 1}${
								errorTextValues?.[propsItem]
							}`,
						});
					});
				});
				Modal.error({
					title: '오류',
					content: (
						<>
							{newErrorText?.map((item) => {
								return <p>{item?.title}</p>;
							})}
						</>
					),
				});
				return;
			}

			sessionStorage.setItem('name', info.name);
			sessionStorage.setItem('representative', info.representative);
			sessionStorage.setItem(
				'phone',
				`${info.phone1}-${info.phone2}-${info.phone3}`,
			);

			window.location.href = `${window.location.href}-update`;
		}
	};

	groupProps.trs = setGroupForm(group, setGroup);
	groupProps.addBtn.onClick = () => setAddGroup(group, setGroup);
	groupProps.deleteBtn.onClick = () => setGroupDelete(group, setGroup);
	groupProps.checkBtn.onClick = () => setAllCheck(group, setGroup);
	groupProps.ths[0].onChange = () =>
		setToggleCheck(group, setGroup, setIsAllCheck);
	groupProps.ths[0].value = isAllCheck;
	secondProps.group = groupProps;
	secondProps.inputs[0].button.onClick = () => handleClick();

	const [newGroup, setNewGroup] = useState([
		{
			key: '0',
			name: '',
			gender: '',
			birth: '',
			phone1: '010',
			phone2: '',
			phone3: '',
			course: '',
			gift: '',
		},
		{
			key: '1',
			name: '',
			gender: '',
			birth: '',
			phone1: '010',
			phone2: '',
			phone3: '',
			course: '',
			gift: '',
		},
	]);

	const getNewGenerateGroupParticipation = (group) => {
		const result = group.map((item) => {
			if (item.gift.split(' - ').length == 2) {
				var gift = item.gift.split(' - ')[0];
				var option = item.gift.split(' - ')[1];
			} else {
				gift = item.gift;
				option = item.option;
			}
			return {
				name: item.name,
				gender: item.gender,
				birth: item.birth,
				phone: `${item.phone1}-${item.phone2}-${item.phone3}`,
				course: item.course,
				deposited: item.deposited,
				created: item.created,
				gift: gift,
				option: option,
			};
		});

		return result;
	};

	const submitButton = async () => {
		if (info.email == '@') {
			info.email = '';
		}
		if (!info.phone1 || !info.phone2 || !info.phone3) {
			return alert('연락처를 입력해 주세요.');
		}
		if (!info.password || info.password.trim().length != 4) {
			return alert('숫자 4자리 비밀번호를 입력해 주세요.');
		}
		if (isValidate(info, invalidProps, setInvalid)) {
			let res = await postGroupParticipation({
				...info,
				birth: `${info.year}-${info.month}-${info.day}`,
				phone: `${info.phone1}-${info.phone2}-${info.phone3}`,
				participation: getNewGenerateGroupParticipation(newGroup),
				raiseError: false,
			});
			if (!res.isSuccess) {
				const newErrorText = [];
				res.data.participation?.map((item, index) => {
					Object.keys(item)?.map((propsItem) => {
						newErrorText.push({
							index,
							title: `${index + 1}${
								errorTextValues?.[propsItem]
							}`,
						});
					});
				});
				if (!res.data.participation) {
					newErrorText.push({
						index: 0,
						title: res.data[0],
					});
				}
				Modal.error({
					title: '오류',
					content: (
						<>
							{newErrorText?.map((item) => {
								return <p>{item?.title}</p>;
							})}
						</>
					),
				});
				return;
			}

			sessionStorage.setItem('name', info.name);
			sessionStorage.setItem('representative', info.representative);
			sessionStorage.setItem(
				'phone',
				`${info.phone1}-${info.phone2}-${info.phone3}`,
			);
			sessionStorage.setItem('password', info.password);

			window.location.href = window.location.origin + '/participation/group-update';
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	return (
		<Container>
			<TopWRapper>
				<BreadCrumb
					depths={['HOME', '참가신청 하기', '단체 참가신청']}
				/>
				<img src={section === 0 ? firstProgress : secondProgress} />
			</TopWRapper>

			{section === 3 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ExpiredImg src={expiredIMG} />
					<ExpiredText>
						마라톤대회 참가 신청이 마감되었습니다.
					</ExpiredText>
				</div>
			)}

			{section === 0 && (
				<React.Fragment>
					<TextArea
						borderRadius={'1rem'}
						height={'228px'}
						disabled={true}
						value={textareaProps}
						fontSize={{
							desktop: '16px',
							tablet: '16px',
							mobile: '16px',
						}}
					/>
					<CheckBoxWrapper>
						<CheckBox
							value={check}
							onChange={(e) => {
								setCheck(e.target.value);
							}}
						></CheckBox>
						<Text>위의 사항에 대하여 모두 동의합니다.</Text>
					</CheckBoxWrapper>
				</React.Fragment>
			)}

			{section === 0 && <PersonForm {...firstProps} />}

			{section === 1 && (
				<PersonForm
					{...secondProps}
					info={info}
					setInfo={setInfo}
					isV2Group={true}
					newGroup={newGroup}
					submitButton={submitButton}
					setNewGroup={setNewGroup}
					isUpdate={false}
				/>
			)}
		</Container>
	);
};

export default Group;

const ButtonRow = styled(Row)({
	marginTop: 20,
});

const SubmitButton = styled(Button)({
	width: 276,
	height: 60,
	fontSize: '1.5em',
	background: '#414c80',
	'&&:hover': {
		background: '#414c80',
	},
});
