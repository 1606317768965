import React, {useState} from 'react';
import {BoardInput} from '../../Organism';
import * as S from './style';
import {Text, Button, FileInput, CheckBox} from '../../Atom';

const BoradForm = ({
	inputs = [],
	description,
	listBtn,
	writeBtn,
	fileInput,
	category,
}) => {
	const [checkBox, setCheckBox] = useState(false);
	return (
		<S.Container>
			{inputs.map((input) => {
				return (
					<S.InputFormWrapper>
						{input.input?.name === 'title' && (
							<BoardInput {...input} />
						)}
						{input.input?.name === 'author' && (
							<>
								<BoardInput {...input} />
							</>
						)}
						{/* {input.input?.name === 'author' && (
							<>
								{((category === '홍보') || (category === '자유')) && (
									<BoardInput {...input} />
								)}
							</>
						)} */}
						{input.input?.name === 'password' &&
							category !== '홍보' &&
							category !== '환불' && (
								<>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<CheckBox
											onChange={(e) =>
												setCheckBox(e.target.value)
											}
											value={checkBox}
										></CheckBox>
										<span style={{marginLeft: '1rem'}}>
											비밀번호 설정(비밀글을 원하시면
											비밀번호를 입력하세요. 비밀글은
											비밀번호 입력 후 확인이 가능합니다.)
										</span>
									</div>
									{checkBox && <BoardInput {...input} />}
								</>
							)}
						{input.input?.name === 'password' &&
							category === '환불' && (
								<>{<BoardInput {...input} />}</>
							)}
						{input.textarea?.name === 'content' && (
							<BoardInput {...input} />
						)}
						{/* {input.input?.name !== 'password' ? (
							<BoardInput {...input} />
						) : category === '환불' ? (
							<BoardInput {...input} />
						) : category === '자유' ? (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<CheckBox
										onChange={(e) =>
											setCheckBox(e.target.value)
										}
										value={checkBox}
									></CheckBox>
									<span style={{marginLeft: '1rem'}}>
										비밀번호 설정
									</span>
								</div>
								{checkBox && <BoardInput {...input} />}
							</>
						) : (
							<></>
						)} */}
					</S.InputFormWrapper>
				);
			})}
			<S.DescriptionWrapper>
				<Text {...description} />
			</S.DescriptionWrapper>
			<S.FileWrapper>
				<Text {...fileInput.text} />
				<FileInput {...fileInput.file} />
			</S.FileWrapper>
			<S.ButtonWrapper>
				<Button {...listBtn} />
				<Button {...writeBtn} />
			</S.ButtonWrapper>
		</S.Container>
	);
};

export default BoradForm;
