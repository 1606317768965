import React, {useState, useEffect} from 'react';
import * as S from './style';
import {
	makeCourse,
	makeDay,
	makeGiftByCourse,
	makeMonth,
	makeYear,
} from '../../../util';
import {
	Form,
	Input,
	Select,
	Button,
	Space,
	Modal,
	List,
	Typography,
} from 'antd';
import {getPerson, updatePerson} from '../../../api/admin';
import {getListGroupParticipation, getListPersonToGroup, postPersonParticipation} from '../../../api';
import {makeGroup} from '../../../util/generator';
import styled from 'styled-components';
import {colorPalette} from '../../../config';

const ModalPersonForm = ({person, onClick}) => {
	const [isGroupModal, setIsGroupModal] = useState(false);
	const [form] = Form.useForm();
	const [groupSelect, setGroupSelect] = useState('그룹을 선택해주세요');
	const [info, setInfo] = useState({
		name: '',
		birth: '',
		post_number: '',
		address: '',
		detail_address: '',
		depositor: '',
		email: '',
		gender: '',
		bib: '',
		course: '',
		gift: '',
		phone1: '',
		phone2: '',
		phone3: '',
		group_id: 0,
		deposited: '',
	});
	const [course, setCourse] = useState('');
	const [group, setGroup] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		(async () => {
			if (person) {
				let res = await getPerson({id: person});
				let verification = sessionStorage.getItem('verification');
				let group_res = await getListPersonToGroup({verification});
				setGroup(group_res.data);
				if (!res.isSuccess) {
					return;
				}
				let splitted_phone = res.data.participation.phone.split('-');
				let splitted_birth = res.data.participation.birth.split('-');
				setInfo({
					...res.data.participation,
					...res.data,
					phone1: splitted_phone[0] ? splitted_phone[0] : '',
					phone2: splitted_phone[1] ? splitted_phone[1] : '',
					phone3: splitted_phone[2] ? splitted_phone[2] : '',
					year: splitted_birth[0],
					month: splitted_birth[1],
					day: splitted_birth[2],
					bib: res.data.participation.bib
						? res.data.participation.bib
						: '',
					email: res.data.email ? res.data.email : '',
				});
			}
			setIsLoading(false);
		})();
	}, []);
	return (
		<S.Container>
			{!isLoading && (
				<Form
					layout={'vertical'}
					initialValues={info}
					onFinish={async (values) => {
						if (values.gift.split(' - ').length == 2) {
							var gift = values.gift.split(' - ')[0];
							var option = values.gift.split(' - ')[1];
						} else {
							gift = values.gift;
							option = null;
						}
						let now = new Date();
						let body = {
							post_number: values.post_number,
							address: values.address,
							detail_address: values.detail_address,
							depositor: values.depositor,
							email: values.email,
							password: values.password,
							participation: {
								birth: `${values.year}-${values.month}-${values.day}`,
								phone: `${values.phone1}-${values.phone2}-${values.phone3}`,
								gender: values.gender,
								course: values.course,
								name: values.name,
								bib: values.bib,
								is_deposit: values.is_deposit,
								gift: gift,
								option: option,
								group_id: info.group_id,
								deposited: values.is_deposit
									? `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}`
									: info.deposited,
							},
						};
						let verification =
							sessionStorage.getItem('verification');
						if (person) {
							let res = await updatePerson({
								id: info.id,
								body,
								verification,
							});
						} else {
							let res = await postPersonParticipation({
								...body,
								...body.participation,
								verification,
							});
							if (!res.isSuccess) {
								return;
							}
						}
						onClick();
					}}
					style={{margin: '1rem'}}
					// labelCol={{span: 4}}
					// wrapperCol={{span: 14}}
					form={form}
				>
					<Form.Item name={'name'} label={'이름'}>
						<Input />
					</Form.Item>
					<Form.Item label={'생년월일'}>
						<Space>
							<Form.Item name={'year'} noStyle>
								<Select
									options={makeYear()}
									style={{width: '100px'}}
								/>
							</Form.Item>
							-
							<Form.Item name={'month'} noStyle>
								<Select
									options={makeMonth()}
									style={{width: '80px'}}
								/>
							</Form.Item>
							-
							<Form.Item name={'day'} noStyle>
								<Select
									options={makeDay()}
									style={{width: '80px'}}
								/>
							</Form.Item>
						</Space>
					</Form.Item>
					<Form.Item name={'post_number'} label={'우편번호'}>
						<Input />
					</Form.Item>
					<Form.Item name={'address'} label={'주소'}>
						<Input />
					</Form.Item>
					<Form.Item name={'detail_address'} label={'상세 주소'}>
						<Input />
					</Form.Item>
					<Form.Item name={'depositor'} label={'입금자명'}>
						<Input />
					</Form.Item>
					<Form.Item name={'email'} label={'이메일'}>
						<Input />
					</Form.Item>
					<Form.Item name={'gender'} label={'성별'}>
						<Select
							options={[
								{name: '남성', value: '남성'},
								{name: '여성', value: '여성'},
							]}
						/>
					</Form.Item>
					<Form.Item name={'bib'} label={'배번호'}>
						<Input />
					</Form.Item>
					<Form.Item name={'password'} label={'비밀번호'}>
						<Input />
					</Form.Item>
					<Form.Item label={'연락처'}>
						<Space align={'center'}>
							<Form.Item name={'phone1'} noStyle>
								<Input />
							</Form.Item>
							-
							<Form.Item name={'phone2'} noStyle>
								<Input />
							</Form.Item>
							-
							<Form.Item name={'phone3'} noStyle>
								<Input />
							</Form.Item>
						</Space>
					</Form.Item>
					<S.RowWrapper></S.RowWrapper>
					<Form.Item name={'course'} label={'코스'}>
						<Select
							options={makeCourse()}
							onChange={() =>
								setCourse(form.getFieldValue('course'))
							}
						/>
					</Form.Item>
					<Form.Item name={'gift'} label={'기념품'}>
						<Select
							options={makeGiftByCourse(
								form.getFieldValue('course'),
							)}
						/>
					</Form.Item>
					<Form.Item name={'option'} label={'옵션'}>
						<Input disabled />
					</Form.Item>
					<Form.Item name={'is_deposit'} label={'입금여부'}>
						<Select
							options={[
								{value: true, label: '입금'},
								{value: false, label: '미입금'},
							]}
						/>
					</Form.Item>
					{person && (
						<Form.Item
							name={'group_id'}
							label={
								<ChangeGroupText>
									단체 전환
									<span>
										*단체 전환을 원할 시 단체명을 클릭 후
										수정버튼을 눌러주세요
									</span>
								</ChangeGroupText>
							}
						>
							{/* <Select options={makeGroup(group)} /> */}
							<StyledButton onClick={() => setIsGroupModal(true)}>
								{groupSelect}
							</StyledButton>
						</Form.Item>
					)}
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button htmlType={'submit'}>
							{person ? '수정' : '생성'}
						</Button>
					</div>
				</Form>
			)}
			<Modal
				open={isGroupModal}
				onCancel={() => setIsGroupModal(false)}
				footer={[]}
			>
				<List
					style={{marginTop: 50, maxHeight: 500, overflow: 'auto'}}
					bordered
					dataSource={group}
					renderItem={(item) => {
						return (
							<List.Item
								onClick={() => {
									setInfo((prev) => ({
										...prev,
										group_id: item?.id,
									}));
									setIsGroupModal(false);
									setGroupSelect(
										`${item?.name} - ${item?.representative}(${item?.phone})`,
									);
								}}
							>
								{`${item?.name} - ${item?.representative}(${item?.phone})`}
							</List.Item>
						);
					}}
				/>
			</Modal>
		</S.Container>
	);
};

export default ModalPersonForm;

const ChangeGroupText = styled.p({
	padding: 0,
	margin: 0,
	'& span': {
		marginLeft: 10,
		color: colorPalette.warn,
	},
});

const StyledButton = styled.div({
	padding: 10,
	borderRadius: 5,
	border: `1px solid ${colorPalette.boardBorder}`,
});
