import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
`;

export const Wrapper = styled.div`
	margin: 0 1rem;
`;

export const RowWrapper = styled.div`
	display: flex;
	align-items: baseline;
`;
